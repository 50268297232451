import React from "react";
import ReactTooltip from "react-tooltip";

function InfoImageEN() {
  return (
    <div className="">
      <svg
        data-tip
        data-for="registerTip"
        className="icon__addon d-xs-none icon__addon--right-info-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16.054"
        height="16.054"
        viewBox="0 0 16.054 16.054"
      >
        <path
          d="M11.4,3.375A8.027,8.027,0,1,0,19.429,11.4,8.026,8.026,0,0,0,11.4,3.375Zm.733,11.732H10.661V9.546h1.474ZM11.4,8.94a.77.77,0,1,1,.8-.772A.774.774,0,0,1,11.4,8.94Z"
          transform="translate(-3.375 -3.375)"
          fill="#b5bd00"
        />
      </svg>
      <ReactTooltip id="registerTip" effect="solid" className="react-tooltip">
        <img
          src="/fileadmin/Infobase_Grafik2_EN.SVG"
          alt=""
          width="450px"
          height="auto"
        />
      </ReactTooltip>
    </div>
  );
}

export default InfoImageEN;
