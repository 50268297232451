import React from "react";
import classNames from "classnames";
import InfoImage from "../icons/InfoImage";
import InfoImageEN from "../icons/InfoImageEN";

export default ({
  label,
  wrapperClassName,
  value,
  onChange,
  name,
  id,
  placeholder,
  ...rest
}) => {
  if (window.js_translations.current_language === "de") {
    return (
      <div
        className={classNames("form-group position-relative", wrapperClassName)}
      >
        {label && (
          <label htmlFor={name}>
            <div className="font-weight-bold">{label}</div>
          </label>
        )}

        <input
          type="text"
          className="form-control has-addon"
          onChange={onChange}
          value={value}
          name={name}
          placeholder={placeholder}
          id={id}
          {...rest}
        />

        <InfoImage />
      </div>
    );
  }

  return (
    <div
      className={classNames("form-group position-relative", wrapperClassName)}
    >
      {label && (
        <label htmlFor={name}>
          <div className="font-weight-bold">{label}</div>
        </label>
      )}

      <input
        type="text"
        className="form-control has-addon"
        onChange={onChange}
        value={value}
        name={name}
        placeholder={placeholder}
        id={id}
        {...rest}
      />

      <InfoImageEN />
    </div>
  );
};
