import React, { useState, useEffect, useRef } from "react";
import map from "lodash/map";
import values from "lodash/values";
import FlipMove from "react-flip-move";
import ProductFilterListRow from "./ProductFilterListRow";
import PaginationFooter from "./PaginationFooter";
import Section from "../../lib/Section";
import apiRequest from "../../utils/apiRequest";
import { toQueryString } from "../../utils/toQueryString";
import useTranslation from "../../lib/hooks/useTranslation";
import usePagination from "../../lib/hooks/usePagination";
import TextInputIcon from "../../lib/forms/TextInputIcon";
import Button from "../../lib/Button";
import getHeaderFields from "../utils/getHeaderFields";

// load features from api

const SearchInput = ({
  initialSearchTerm = "",
  onSearchstringSubmit,
  setPage,
}) => {
  const [search, setSearch] = useState(initialSearchTerm);
  const translations = useTranslation();
  return (
    <div className="d-block d-lg-flex align-items-center justify-content-between px-2">
      <TextInputIcon
        onChange={(ev) => setSearch(ev.target.value)}
        value={search}
        wrapperClassName="w-100 mr-0 mr-lg-3 mb-2 mb-lg-0"
        placeholder={translations.search_field_text}
        onKeyPress={(e) => e.key === "Enter" && onSearchstringSubmit(search)}
      />
      <Button
        onClick={() => {
          onSearchstringSubmit(search);
          setPage(1);
        }}
        className="text-white btn-primary col-12 col-lg-2"
      >
        {translations.search_button_text}
      </Button>
    </div>
  );
};

const RadioButton = ({ label, onClick, filterStatus }) => {
  const activeClass = filterStatus === label ? "active" : "";

  return (
    <button
      className={`d-inline-block tag tag--cloud rounded px-3 text-sm tag--primary js-pdf-filter-product-uuid ${activeClass}`}
      onClick={onClick}
      data-filter={label}
      type="button"
    >
      {label}
    </button>
  );
};

const FilterPanel = ({ options, onClick, filterStatus }) => {
  const translations = useTranslation();

  return (
    <div className="px-2 my-2 my-lg-0">
      <h5 className="heading-sm mb-3 font-weight-bold mt-3">Filter</h5>

      {map(options, (option) => (
        <RadioButton
          key={option.label}
          label={translations[option.translation_key]}
          onClick={onClick}
          filterStatus={filterStatus}
        />
      ))}
    </div>
  );
};

const ProductHeader = ({ tableAttributes }) => (
  <div className="product-table-header row px-4 py-3 d-none d-md-flex">
    {tableAttributes.map((columnTitle) => (
      <div className="col-12 col-lg p-0" key={columnTitle}>
        <div className="font-weight-bold">{columnTitle}</div>
      </div>
    ))}
  </div>
);

const InfoBaseItems = ({
  tableAttributes,
  items,
  table,
  isExpandable,
  numberPages,
  currentPage,
  setPage,
  filterState,
}) => {
  const [sliceItems, setSliceItems] = useState(true);
  const translations = useTranslation();

  return (
    <>
      <ProductHeader tableAttributes={tableAttributes} />
      <div className="row">
        <div className="col-12">
          <div className="product-filter__table px-2">
            <FlipMove
              duration={300}
              easing="ease-out"
              className="product-filter__table__innerContainer"
            >
              {items
                .slice(
                  0,
                  sliceItems &&
                    filterState === translations.filter_status_all &&
                    items.length > 3
                    ? 3
                    : items.length
                )
                .map((item) => (
                  <div>
                    <ProductFilterListRow
                      product={item}
                      table={table}
                      tableAttributes={tableAttributes}
                      isExpandable={isExpandable}
                    />
                  </div>
                ))}
            </FlipMove>
          </div>
        </div>
      </div>
      {filterState === translations.filter_status_all && items.length > 3 ? (
        <div className="row">
          <div className="col-12">
            <div className="px-2 mt-4">
              <button
                onClick={() => {
                  setSliceItems((currentStatus) => !currentStatus);
                  setPage(1);
                }}
                type="button"
                className="link font-weight-bold info-item__link d-flex align-items-center bg-transparent border-0 text-primary"
              >
                {sliceItems
                  ? translations.info_base_show_more
                  : translations.info_base_show_less}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.414"
                  height="14.836"
                  viewBox="0 0 8.414 14.836"
                  className="ml-3"
                >
                  <path
                    className="icon icon__chevron icon--small icon--primary icon--thin"
                    d="M0,6,6.007,0s5.99,5.987,6,6"
                    transform="translate(7.414 1.414) rotate(90)"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {!sliceItems || filterState !== translations.filter_status_all ? (
        <PaginationFooter
          numberPages={numberPages}
          currentPage={currentPage}
          setPage={setPage}
        />
      ) : null}
    </>
  );
};

/**
 *
 * @param props {{data: object, isExpandable?: boolean}}
 * @returns {JSX.Element}
 */
const InfoBaseList = ({ data, isExpandable = false }) => {
  const {
    api: { baseUrl, initialFilter = {} },
    showFrontend: { table },
    products: initialProducts = {},
  } = data;

  const documentTable = {
    product_fields: ["asset_document_id ", "asset_title"],
  };

  const translations = useTranslation();
  const queryString = window.location.search;
  const queryRegex = /^[?tx_jhproducts_infobase%5Bsearch%5D]/i;

  const { productType } = initialFilter;
  const [loaded, setLoaded] = useState(false);

  const [productsById, setProducts] = useState(initialProducts.items);
  const [products, currentPage, numberPages, setPage] = usePagination({
    collection: values(productsById),
  });

  const [documentsById, setDocuments] = useState();
  const [documents, currentDocPage, numberDocPages, setDocPage] = usePagination(
    {
      collection: values(documentsById),
    }
  );

  const [searchstring, setSearchstring] = useState(getCurrentSearchTerm);
  const tableAttributes = getHeaderFields({
    table,
    translations,
    features: [],
  });

  const initialStatus = translations.filter_status_all;
  const [filterStatus, setFilterStatus] = useState(initialStatus);
  // const [options, setOptions] = useState(initialOptions.items);

  const options = [
    { label: "Alle", value: "Alle", translation_key: "filter_status_all" },
    {
      label: "Produkte",
      value: "Produkte",
      translation_key: "filter_status_products",
    },
    {
      label: "Dokumente",
      value: "Dokumente",
      translation_key: "filter_status_documents",
    },
  ];

  const fetchProducts = () => {
    const baseQuery = toQueryString({
      productType,
      searchstring,
    });

    const url = `${baseUrl}${baseQuery}&row=500&start=0`;

    apiRequest({ url, stubDev: true }).then(({ products: { items } }) => {
      setProducts(values(items).sort(sortProductsByID));
      setLoaded(true);
    });
  };

  const fetchDocuments = () => {
    const baseQuery = toQueryString({
      searchstring,
    });

    const url = `${baseUrl}&productType=5${baseQuery}&row=500&start=0`;

    apiRequest({ url, stubDev: true }).then(
      ({ assets: { items: assetItems } }) => {
        setDocuments(values(assetItems));
        setLoaded(true);
      }
    );
  };

  const didMount = useRef(false);

  useEffect(() => {
    if (queryRegex.test(queryString) || didMount.current) {
      fetchProducts();
      fetchDocuments();
      setHistory(searchstring);
    } else {
      didMount.current = true;
    }
  }, [searchstring, data]);

  return (
    <Section className="mb-5">
      <h5 className="mb-5 text-center font-weight-bold">
        {translations.title}
      </h5>
      <div className="row">
        <div className="col-12 mb-3">
          <SearchInput
            initialSearchTerm={searchstring}
            onSearchstringSubmit={setSearchstring}
            setPage={() => setPage(1)}
          />
        </div>
      </div>
      <div className="row">
        {loaded && (
          <>
            {documents.length > 0 && products.length > 0 ? (
              <div className="col-12 col-md-4 order-md-2">
                <FilterPanel
                  options={options}
                  onClick={(e) => {
                    setFilterStatus(e.target.dataset.filter);
                    setPage(1);
                  }}
                  filterStatus={filterStatus}
                />
              </div>
            ) : null}
            <div className="col-12 col-md-8 mb-3">
              {(products.length > 0 &&
                filterStatus === translations.filter_status_all) ||
              filterStatus === translations.filter_status_products ? (
                <div className="mb-4">
                  <InfoBaseItems
                    items={products}
                    currentPage={currentPage}
                    isExpandable={isExpandable}
                    table={table}
                    tableAttributes={tableAttributes}
                    numberPages={numberPages}
                    setPage={setPage}
                    filterState={filterStatus}
                  />
                </div>
              ) : null}

              {(documents.length > 0 &&
                filterStatus === translations.filter_status_all) ||
              filterStatus === translations.filter_status_documents ? (
                <InfoBaseItems
                  items={documents}
                  currentPage={currentDocPage}
                  isExpandable={isExpandable}
                  table={documentTable}
                  tableAttributes={[
                    translations.info_base_items_document_id,
                    translations.info_base_items_documents,
                  ]}
                  numberPages={numberDocPages}
                  setPage={setDocPage}
                  filterState={filterStatus}
                />
              ) : null}

              {documents.length <= 0 && products.length <= 0 ? (
                <div className="py-2 text-center my-4">
                  <span className="font-weight-bold">
                    {translations.filter_results_none_long}
                  </span>
                  <p>{translations.filter_results_none_hint_long}</p>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
    </Section>
  );
};

/**
 * @param {string} string
 * @returns {number}
 */
const convertIDtoINT = (string) => {
  const numFromString = parseInt(string.split("-").join(""), 10);

  return numFromString;
};

/**
 * @param {{product_xml_supplier_aid: string}} a
 * @param {{product_xml_supplier_aid: string}} b
 * @returns {number}
 */
const sortProductsByID = (a, b) =>
  convertIDtoINT(a.product_xml_supplier_aid) -
  convertIDtoINT(b.product_xml_supplier_aid);

const INFOBASE_SEARCH_PARAM_NAME = "tx_jhproducts_infobase[search]";

/**
 * @param {string} searchString
 */
const setHistory = (searchString) => {
  const url = new URL(window.location);
  if (searchString) {
    url.searchParams.set(INFOBASE_SEARCH_PARAM_NAME, searchString);
  } else {
    url.searchParams.delete(INFOBASE_SEARCH_PARAM_NAME);
  }
  window.history.pushState({}, "", url);
};

/**
 * Read the search term from the current window.location.
 *
 * @returns {string}
 */
const getCurrentSearchTerm = () => {
  const url = new URL(window.location);
  return url.searchParams.get(INFOBASE_SEARCH_PARAM_NAME) ?? "";
};

export default InfoBaseList;
